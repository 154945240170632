import { makeStyles } from '@material-ui/core/styles';

export default makeStyles (() => ({

    appBar: {
        borderRadius: 15,
        margin: '30px 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },
      sensorBar: {
        borderRadius: 15,
        margin: '30px 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },
      buttonGroup: {
        borderRadius: 15,
        margin: '30px 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },
      heading: {
        color: '#282763',
      },
      image: {
        marginLeft: '15px',
      },
      body: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        backgroundImage: 'url("images/dark.jpeg")',
        backgroundRepeat: 'repeat-y',
        backgroundSize: '100%',
        paddingBottom: '15vh',
      }
      
  
}));