import ToggleFormField from "../ToggleFormField/ToggleFormField";
import Filter from "../Filter/Filter";
import useStyles from "./styles";

export default function Navigation({ setFilter, setCurrentId, currentId }) {
    const classes = useStyles();
      return (
    <div className={`main__navigation ${classes.mainnavigation}`}>
      <ToggleFormField className={`pogas`} setCurrentId={setCurrentId} currentId={currentId} />
      <Filter className={`pogas`} setFilter={setFilter}></Filter>
    </div>
  );
}