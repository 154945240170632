import axios from "axios";
import React, { useState, useEffect } from "react";
import { Container, AppBar, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

const baseURL =
  
  "https://api.thingspeak.com/channels/1531405/feeds.json?api_key=D3LD31UQDZ3W4F7M&results=1";
//"https://api.thingspeak.com/channels/1481991/status.json?api_key=A80QELDUFKQYY5GN";

const weatUrl = "https://seatemperature.info/j.php?name=engure";

export default function Sensor() {
  const [post, setPost] = useState(null);

  useEffect(() => {
    axios.get(baseURL).then((response) => {
      setPost(response.data);
    });
  }, []);


   if (!post) return null;

  


  return (
  
     
        <Typography variant="subtitle2" align="center"> 
        <img src="loc.svg"  height="20"  alt="long - lat"/> 
      <img src="udens.svg"  height="20"  alt="t"/> 
       
        </Typography>
        
  );
}
//57.162-23.241&nbsp; &nbsp;
//pH  {post.feeds[0].field3} &nbsp; &nbsp;  h- {post.feeds[0].field4}

//export default Sensor;
/*
return (
  <Container maxWidth="lg">
    <AppBar position="static" color="inherit">
      <Typography variant="subtitle1" align="center"> 
    
      <img src="loc.svg"  height="20"  alt="long - lat"/> {post.channel.latitude}-{post.channel.longitude}&nbsp; &nbsp; 
      <img src="time.svg"  height="20"  alt="laiks"/> {post.feeds[0].created_at}  <br/> 
      <img src="temp.svg"  height="20"  alt="t"/> {post.feeds[0].field1}    &nbsp; &nbsp;
      <img src="temp_.svg"  height="20"  alt="t"/> {post.feeds[0].field2}  &nbsp; &nbsp; 
      <img src="udens.svg"  height="20"  alt="t"/> pH  {post.feeds[0].field3} &nbsp; &nbsp; 
       h- {post.feeds[0].field4}, 
        periods- {post.feeds[0].field5}, 
        enerģija- {post.feeds[0].field6} 
      </Typography>
    </AppBar>
  </Container>
);
}
*/


/*
      <img src="loc.svg"  height="20"  alt="long - lat"/> 57.164143, 23.231661 &nbsp; &nbsp; 
        <img src="time.svg"  height="20"  alt="laiks"/> {post.feeds[0].created_at}   &nbsp; &nbsp; 
       
        <img src="temp.svg"  height="20"  alt="t"/> 7    &nbsp; &nbsp;
        <img src="temp_.svg"  height="20"  alt="t"/> 6  &nbsp; &nbsp; 
        <img src="udens.svg"  height="20"  alt="t"/> pH  {post.feeds[0].field3} &nbsp; &nbsp; 
         h- 0.2, 
          periods- 2, 
          enerģija- 0*/

          /*
          
             <Typography variant="subtitle2" align="center"> 
      <img src="loc.svg"  height="20"  alt="long - lat"/> 57.164143, 23.231661 &nbsp; &nbsp; 
      <img src="time.svg"  height="20"  alt="laiks"/> {post.feeds[0].created_at}  <br/> 
      <img src="temp.svg"  height="20"  alt="t"/> {post.feeds[0].field1}    &nbsp; &nbsp;
      <img src="temp_.svg"  height="20"  alt="t"/> {post.feeds[0].field2}  &nbsp; &nbsp; 
      <img src="udens.svg"  height="20"  alt="t"/> pH  {post.feeds[0].field3} &nbsp; &nbsp; 
       h- {post.feeds[0].field4}, 
        periods- {post.feeds[0].field5}, 
        enerģija- {post.feeds[0].field6} 
        </Typography>*/