import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import {  Grow, Grid } from "@mui/material";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import useStyles from "./styles";
import { Container, AppBar, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import { getPosts } from "./actions/posts";

import Navigation from "./components/Navigation/Navigation";
import Posts from "./components/Posts/Posts";
import Form from "./components/Form/Form";
import Sensor from "./components/Sensor/Sensor";
import Weather from "./components/Weather/Weather";

export default function App() {
  const [currentId, setCurrentId] = useState(0);
  const [filteredData, setFilter] = useState([]);

  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPosts());
  }, [currentId, dispatch]);

  const [mode, setMode] = React.useState("dark");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          secondary: {
            main: '#202121'
          }, 
         primary: {
            main: '#12a5b0'
          }
        },
      }),
    [mode]
  );

  return (
    <ThemeProvider theme={theme}>
         <Container maxWidth="lg">
      <AppBar position="static" color="inherit">
      <Typography variant="h4" align="center"> 
     Engures sludinājumu dēlis
        </Typography>
      <Toolbar variant="dense" align="center"> 
      <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
      <img src="boja.png"  width="40"  alt="Engures boja"/>
    </IconButton>
      <Sensor />
      <Weather />
      </Toolbar>
 
 </AppBar>
</Container>
      <Container maxWidth="lg">
        <Navigation
          setFilter={setFilter}
          setCurrentId={setCurrentId}
          currentId={currentId}
        />

        <Posts filteredData={filteredData} setCurrentId={setCurrentId} />
      </Container>
    </ThemeProvider>
  );
}