
import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({

mainnavigation : {
    gap: "0",
  
},

pogas : {
    color: 'rgba(12, 0, 200, 0)',
  
},

});